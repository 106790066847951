import React from 'react'
import 'lazysizes'
import { IntersectionObserverProvider } from "./src/provider/IntersectionObserverProvider"
const resetPage= () =>{
  const targetList = document.querySelectorAll('a')
  targetList.forEach(function (target) {
    if(target.target !== "_blank"){
      target.addEventListener('click', ()=>{
        if(document && document.querySelector("main")){
          document.querySelector("main").classList.add("loading")
          document.querySelector(".m_head").ariaExpanded = false;
          document.querySelector(".m_head_top").ariaExpanded = false;
          document.querySelector(".m_head_menu_outer").ariaExpanded = false;
        }
      });
    }
  });
}
export const onRouteUpdate = ({ location, prevLocation }) => {
  if (typeof window.FONTPLUS !== "undefined"){
    window.FONTPLUS.reload()
  }
  document.querySelector("main").classList.remove("loading")
  document.querySelector("header").classList.remove("stay")
  window.previousPath = prevLocation ? prevLocation.pathname : null;
  resetPage()
}

export const wrapRootElement = ({ element }) => (
  <IntersectionObserverProvider>{element}</IntersectionObserverProvider>
)