exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-books-js": () => import("./../../../src/pages/books.js" /* webpackChunkName: "component---src-pages-books-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-personal-js": () => import("./../../../src/pages/personal.js" /* webpackChunkName: "component---src-pages-personal-js" */),
  "component---src-pages-profile-js": () => import("./../../../src/pages/profile.js" /* webpackChunkName: "component---src-pages-profile-js" */),
  "component---src-pages-works-js": () => import("./../../../src/pages/works.js" /* webpackChunkName: "component---src-pages-works-js" */),
  "component---src-templates-books-books-js": () => import("./../../../src/templates/books/books.js" /* webpackChunkName: "component---src-templates-books-books-js" */),
  "component---src-templates-essay-archive-js": () => import("./../../../src/templates/essay/archive.js" /* webpackChunkName: "component---src-templates-essay-archive-js" */),
  "component---src-templates-essay-essay-js": () => import("./../../../src/templates/essay/essay.js" /* webpackChunkName: "component---src-templates-essay-essay-js" */),
  "component---src-templates-news-archive-js": () => import("./../../../src/templates/news/archive.js" /* webpackChunkName: "component---src-templates-news-archive-js" */),
  "component---src-templates-news-news-js": () => import("./../../../src/templates/news/news.js" /* webpackChunkName: "component---src-templates-news-news-js" */),
  "component---src-templates-personal-personal-js": () => import("./../../../src/templates/personal/personal.js" /* webpackChunkName: "component---src-templates-personal-personal-js" */),
  "component---src-templates-works-tags-works-js": () => import("./../../../src/templates/works/tags/works.js" /* webpackChunkName: "component---src-templates-works-tags-works-js" */),
  "component---src-templates-works-works-js": () => import("./../../../src/templates/works/works.js" /* webpackChunkName: "component---src-templates-works-works-js" */)
}

